.dot {
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #c1a246;
}
